<template>
  <page-header-wrapper>
    <div class="rc-its-wrap">
      <a-card :bordered="false">
        <!-- 条件搜索 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="8" :sm="24">
                <a-form-item label="驿站" prop="stationName">
                  <a-select
                    show-search
                    allowClear
                    style="width: 100%"
                    placeholder="请输入驿站模糊查找"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handleQuerySearch"
                    @change="handleQueryChange"
                    :loading="hsloading"
                    v-model="queryParam.stationName"
                  >
                    <a-select-option v-for="(item, index) in stationListQuery" :key="index" :value="item.name">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="智能秤" prop="deviceId">
                  <a-select
                    show-search
                    allowClear
                    style="width: 100%"
                    placeholder="请输入智能秤ID模糊查找"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handledeviceSearch"
                    @change="handledeviceChange"
                    :loading="hsloading"
                    v-model="queryParam.deviceId"
                  >
                    <a-select-option v-for="(item, index) in deviceList" :key="index" :value="item.deviceId">
                      {{ item.deviceId }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <template v-if="advanced">
                <a-col :md="8" :sm="24">
                  <a-form-item label="最近登录账户" prop="stationName">
                    <a-select
                      show-search
                      allowClear
                      style="width: 100%"
                      placeholder="请输入最近登录账户模糊查找"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="handleLoginSearch"
                      @change="handleLoginChange"
                      :loading="logloading"
                      v-model="queryParam.lastLoginName"
                    >
                      <a-select-option v-for="(item, index) in logList" :key="index" :value="item.userName">
                        {{ item.userName }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- <a-col :md="8" :sm="24">
                  <a-form-item label="锁定标识" prop="lockFlag">
                    <a-select
                      placeholder="请选择锁定标识"
                      v-model="queryParam.lockFlag"
                      style="width: 100%"
                      allow-clear
                    >
                      <a-select-option v-for="(item, index) in lockFlagOptions" :key="index" :value="item.dictValue">{{
                        item.dictLabel
                      }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col> -->
              </template>
              <a-col :md="(!advanced && 8) || 24" :sm="24">
                <span
                  class="table-page-search-submitButtons"
                  :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
                >
                  <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                  <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                  <a @click="toggleAdvanced" style="margin-left: 8px">
                    {{ advanced ? '收起' : '展开' }}
                    <a-icon :type="advanced ? 'up' : 'down'" />
                  </a>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <!-- 操作 -->
        <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
          <div class="table-operations">
            <a-button
              type="primary"
              @click="$refs.createForm.handleAdd()"
              v-hasPermi="['tenant:recyclable:intelligentScale:add']"
            >
              <a-icon type="plus" />新增
            </a-button>
            <a-button
              type="primary"
              :disabled="single"
              @click="$refs.createForm.handleUpdate(undefined, ids)"
              v-hasPermi="['tenant:recyclable:intelligentScale:edit']"
            >
              <a-icon type="edit" />修改
            </a-button>
            <a-button
              type="danger"
              :disabled="multiple"
              @click="handleDelete"
              v-hasPermi="['tenant:recyclable:intelligentScale:remove']"
            >
              <a-icon type="delete" />删除
            </a-button>
            <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:recyclable:intelligentScale:export']">
              <a-icon type="download" />导出
            </a-button>
            <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
          </div>
        </a-toolbar>
        <!-- 增加修改 -->
        <create-form
          ref="createForm"
          @ok="getList"
          :stationLis="stationList"
          :statusOptions="statusOptions"
          :lockFlagOptions="lockFlagOptions"
          :optionsFormat="optionsFormat"
        />
        <!-- 数据展示 -->
        <a-table
          :loading="loading"
          :size="tableSize"
          rowKey="id"
          tid="1"
          :columns="columns"
          :data-source="list"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :pagination="false"
        >
          <span slot="createTime" slot-scope="text, record">
            {{ parseTime(record.createTime) }}
          </span>
          <span slot="operation" slot-scope="text, record">
            <a-divider type="vertical" v-hasPermi="['tenant:recyclable:intelligentScale:log']" />
            <a @click="logininfor(record)" v-hasPermi="['tenant:recyclable:intelligentScale:log']">
              <a-icon type="edit" />登录日志
            </a>
            <a-divider type="vertical" v-hasPermi="['tenant:recyclable:intelligentScale:edit']" />
            <a
              @click="$refs.createForm.handleUpdate(record, undefined)"
              v-hasPermi="['tenant:recyclable:intelligentScale:edit']"
            >
              <a-icon type="edit" />修改
            </a>
            <a-divider type="vertical" v-hasPermi="['tenant:recyclable:intelligentScale:remove']" />
            <a @click="handleDelete(record)" v-hasPermi="['tenant:recyclable:intelligentScale:remove']">
              <a-icon type="delete" />删除
            </a>
            <a-divider type="vertical" />
            <a v-if="!record.postStationId" @click="handleBind(record)"> <a-icon type="plus-circle" />绑定驿站 </a>
            <a v-else @click="handleUnBind(record)"> <a-icon type="plus-circle" />解绑驿站 </a>
            <a-divider
              type="vertical"
              v-if="record.lockFlag === 0"
              v-hasPermi="['tenant:recyclable:intelligentScale:lock']"
            />
            <a
              @click="handleLock(record)"
              v-if="record.lockFlag === 0"
              v-hasPermi="['tenant:recyclable:intelligentScale:lock']"
            >
              <a-icon type="lock" />锁定
            </a>
            <a-divider
              type="vertical"
              v-if="record.lockFlag === 1"
              v-hasPermi="['tenant:recyclable:intelligentScale:unlock']"
            />
            <a
              v-if="record.lockFlag === 1"
              @click="handleUnlock(record)"
              v-hasPermi="['tenant:recyclable:intelligentScale:unlock']"
            >
              <a-icon type="unlock" />解锁
            </a>
          </span>
        </a-table>
        <vxe-modal
          v-model="logModalVisible"
          width="50%"
          title="登录日志"
          :show-zoom="true"
          resize
          :lock-view="false"
          :mask="false"
          position="center"
          @close="handleCloseModal"
          class="vxe-container"
          transfer
        >
          <a-table :size="tableSize" rowKey="id" :columns="logColumns" :data-source="logList" :pagination="false">
          </a-table>
        </vxe-modal>
        <vxe-modal
          v-model="bindModalVisible"
          title="绑定驿站"
          :show-zoom="true"
          resize
          :lock-view="false"
          :mask="false"
          position="center"
          @close="handleCloseModal"
          class="vxe-container"
          transfer
        >
          <a-select
            show-search
            allowClear
            style="width: 100%"
            placeholder="请输入驿站模糊查找"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleStationSearch"
            @change="handleStationChange"
            :loading="hsloading"
            v-model="postStationId"
          >
            <a-select-option v-for="(item, index) in stationList" :key="index" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <div class="bottom-control">
            <a-space>
              <a-button type="primary" @click="bindStation" :loading="bindLoading">绑定</a-button>
              <a-button type="dashed" @click="cancel"> 取消 </a-button>
            </a-space>
          </div>
        </vxe-modal>

        <!-- 分页 -->
        <a-pagination
          class="ant-table-pagination"
          show-size-changer
          show-quick-jumper
          :current="queryParam.pageNum"
          :total="total"
          :page-size="queryParam.pageSize"
          :showTotal="(total) => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="changeSize"
        />
      </a-card>
    </div>
  </page-header-wrapper>
</template>

<script>
import {
  listIntelligentScale,
  delIntelligentScale,
  exportIntelligentScale,
  lock,
  unlock,
  bindStation,
  search
} from '@/api/recyclable/intelligentScale'
import { listLastLoginName } from '@/api/recyclable/logininfor'
import { listAll, unBindScale } from '@/api/recyclable/postStationInfo'
import CreateForm from './modules/CreateForm'
import debounce from 'lodash/debounce'

export default {
  name: 'IntelligentScale',
  components: {
    CreateForm
  },
  data() {
    this.handleStationSearch = debounce(this.handleStationSearch, 500)
    this.handleQuerySearch = debounce(this.handleQuerySearch, 500)
    this.handleLoginSearch = debounce(this.handleLoginSearch, 500)
    this.handledeviceSearch = debounce(this.handledeviceSearch, 500)
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      bindLoading: false,
      hsloading: false,
      logloading: false,
      deviceloading: false,
      postStationId: undefined,
      total: 0,
      // 查询参数
      queryParam: {
        deviceId: undefined,
        manufactor: null,
        tenantCode: null,
        stationName: undefined,
        status: null,
        lockFlag: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '设备ID',
          dataIndex: 'deviceId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备厂家',
          dataIndex: 'manufactor',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '驿站名称',
          dataIndex: 'stationName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '锁定标识',
          dataIndex: 'lockFlag',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return this.optionsFormat(this.lockFlagOptions, record.lockFlag)
          }
        },
        {
          title: '最近登录账户',
          dataIndex: 'lastLoginName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      statusOptions: [
        { dictLabel: '正常', dictValue: '0' },
        { dictLabel: '删除', dictValue: '1' }
      ],
      lockFlagOptions: [
        { dictLabel: '正常', dictValue: '0' },
        { dictLabel: '锁定', dictValue: '1' }
      ],
      logModalVisible: false,
      logList: [],
      logColumns: [
        {
          title: '登录账户',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '登录时间',
          dataIndex: 'loginTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '登录状态',
          dataIndex: 'msg',
          ellipsis: true,
          align: 'center'
        }
      ],
      stationList: [],
      stationListQuery: [],
      deviceList: [],
      bindModalVisible: false,
      stationInfo: {
        id: '',
        name: ''
      }
    }
  },
  filters: {},
  created() {
    this.queryParam.stationName = this.$route.query.stationName
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    handleStationSearch(value) {
      console.log('handleStationSearch', value)
      this.hsloading = true
      this.listPostStationInfo(value, 'stationList', 'hsloading')
    },
    handleStationChange(value) {
      console.log('handleStationChange', value)
      this.stationInfo.id = value
      const tmpArray = this.stationList.filter((p) => p.id === value)
      if (tmpArray.length > 0) {
        this.stationInfo.name = tmpArray[0].name
      }
    },
    handleQuerySearch(value) {
      console.log('handleQuerySearch', value)
      this.qloading = true
      this.listPostStationInfo(value, 'stationListQuery', 'qloading')
    },
    handleQueryChange(value) {
      console.log('handleQueryChange', value)
      this.queryParam.stationName = value
    },
    /** 查询智能秤信息列表 */
    getList() {
      this.loading = true
      listIntelligentScale(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        deviceId: undefined,
        manufactor: undefined,
        tenantCode: undefined,
        stationName: undefined,
        status: undefined,
        lockFlag: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delIntelligentScale(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportIntelligentScale(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    optionsFormat(options, value) {
      return this.selectDictLabel(options, value)
    },
    handleLock(record) {
      var that = this
      this.$confirm({
        title: '是否确认锁定?',
        content: '当前选中编号为' + record.id + '的数据',
        onOk() {
          return lock(record.id).then((res) => {
            that.getList()
            that.$message.success('锁定成功', 3)
          })
        },
        onCancel() {}
      })
    },
    handleUnlock(record) {
      var that = this
      this.$confirm({
        title: '是否确认解锁?',
        content: '当前选中编号为' + record.id + '的数据',
        onOk() {
          return unlock(record.id).then((res) => {
            that.getList()
            that.$message.success('解锁成功', 3)
          })
        },
        onCancel() {}
      })
    },
    logininfor(record) {
      // 查看登入登出日志
      // logininfor(record.deviceId).then(res => {
      //   this.logModalVisible = true
      //   this.logList = res.data
      // })
      this.$router.push({
        path: '/recyclable/log/rclogininfor'
      })
    },
    handleCloseModal() {
      this.logModalVisible = false
    },
    bindStation() {
      this.bindLoading = true
      // 绑定驿站
      bindStation({
        id: this.scaleId,
        stationId: this.stationInfo.id,
        stationName: this.stationInfo.name
      })
        .then((res) => {
          this.bindLoading = false
          this.$message.success('绑定成功', 3)
          this.bindModalVisible = false
          this.getList()
        })
        .catch(() => {
          this.bindLoading = false
        })
    },
    listPostStationInfo(name, arrName, loadingName) {
      listAll({
        stationName: name
      }).then((res) => {
        this[loadingName] = false
        this[arrName] = res.data
      })
    },
    popupScroll(e) {
      console.log('popupScroll', e)
    },
    handleChange(e) {
      console.log('handleChange', e)
      for (const item of this.stationList) {
        if (item.id === e) {
          this.stationInfo = item
        }
      }
    },
    handleBind(res) {
      this.scaleId = res.id
      this.bindModalVisible = true
    },
    handleUnBind(record) {
      var that = this
      this.$confirm({
        title: '是否确认解绑?',
        content: '此操作将解绑当前智能秤绑定的驿站',
        onOk() {
          return unBindScale(record.deviceId).then((response) => {
            that.unbindLoading = false
            that.unbindDeviceId = undefined
            that.getList()
            that.$message.success('解绑成功', 3)
          })
        },
        onCancel() {}
      })
    },
    cancel() {
      this.bindModalVisible = false
      this.logModalVisible = false
    },
    listLastLoginName(lastLoginName) {
      // 最近登录账户
      listLastLoginName({
        lastLoginName
      }).then((res) => {
        this.logloading = false
        this.logList = res.data
      })
    },
    handleLoginSearch(value) {
      console.log('handleLoginSearch', value)
      this.logloading = true
      this.listLastLoginName(value)
    },
    handleLoginChange(value) {
      this.queryParam.lastLoginName = value
    },
    handledeviceSearch(value) {
      this.deviceloading = true
      this.deviceSearch(value)
    },
    handledeviceChange(value) {
      this.queryParam.deviceId = value
    },
    deviceSearch(deviceId) {
      search({
        deviceId
      }).then((res) => {
        this.deviceloading = false
        this.deviceList = res.data
      })
    }
  }
}
</script>
<style lang="less" scoped>
.vxe-modal--wrapper .vxe-modal--content > div {
  height: auto !important;
}

.bottom-control {
  margin-top: 20px;
}
</style>
